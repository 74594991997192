import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { GatsbyImage } from "gatsby-plugin-image";

const AboutImg = ({ filename, alt }) => (
  <StaticQuery
    query={graphql`{
  images: allFile {
    edges {
      node {
        relativePath
        name
        childImageSharp {
          gatsbyImageData(width: 350, layout: FIXED)
        }
      }
    }
  }
}
`}
    render={data => {
      const image = data.images.edges.find(n => {
        return n.node.relativePath.includes('Pablo_headshot.jpg');
      });

      if (!image) return null;

      const imageFixed = image.node.childImageSharp.gatsbyImageData;
      return <GatsbyImage image={imageFixed} className="rounded shadow-lg" alt={alt} />;
    }}
  />
);

AboutImg.propTypes = {
  filename: PropTypes.string,
  alt: PropTypes.string,
};

export default AboutImg;
