import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';

const RITImg = ({ alt }) => (
  <StaticQuery
    query={graphql`
      {
  images: allFile {
    edges {
      node {
        relativePath
        name
        childImageSharp {
          gatsbyImageData(width: 360, layout: FIXED)
        }
      }
    }
  }
}
`}
    render={data => {
      const image = data.images.edges.find(n => {
        return n.node.relativePath.includes('RITLogo');
      });

      if (!image) return null;

      const imageFixed = image.node.childImageSharp.gatsbyImageData;
      return <GatsbyImage image={imageFixed} className="rounded" alt={alt} />;
    }}
  />
);

RITImg.propTypes = {
  //  filename: PropTypes.string,
  alt: PropTypes.string,
};

export default RITImg;
